import React from "react";
import Header from "../../../shared-components/header/header-controller";
import Footer from "../../../shared-components/footer/footer-controller";
import { makeStyles } from "@material-ui/core/styles";

import { getLocaleValue } from "../../../../helpers/get-locale-value";

import NewsCategoryListController from "../../category/news-category-list-controller";
import NewsListItemController from "./news-list-item-controller";
import SEO from "../../../shared-components/seo";
import Container from "@material-ui/core/Container";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  row: {
    borderBottom: "1px solid #ddd",
    marginTop: "20px",
    marginBottom: "0px",
    paddingBottom: "10px",
    textAlign: "center",
  },
});

const NewsListView = ({
  locale,
  location,
  header,
  footer,
  siteSetting,
  data,
  pageTitleVariants,
  categories,
  margin,
  padding,
  zIndex,
  isDisplayTitle,
  numberPerPage,
  presentation,
  sectionType,
  titleVariants,
  anchorLink,
  reloadRequired,
}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight="100vh"
    >
      <SEO siteSetting={siteSetting} />
      <Header
        header={header}
        siteSetting={siteSetting}
        location={location}
        reloadRequired={reloadRequired}
      />
      <Container style={{ flex: 1 }}>
        <Typography variant="h4" className={classes.row}>
          {getLocaleValue(locale, pageTitleVariants).content}
        </Typography>

        <NewsCategoryListController
          categories={categories}
          location={location}
          currentNewsCategory={null}
        />

        {data?.map((n, i) => (
          <NewsListItemController key={i} data={n} />
        ))}
      </Container>
      <Footer footer={footer} siteSetting={siteSetting} />
    </Box>
  );
};

export default NewsListView;
