import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../../../shared-components/header/header-controller";
import Footer from "../../../shared-components/footer/footer-controller";

import { getLocaleValue } from "../../../../helpers/get-locale-value";

import NewsListItemController from "./news-list-item-controller";
import NewsCategoryListController from "../../category/news-category-list-controller";
import SEO from "../../../shared-components/seo";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  row: {
    borderBottom: "1px solid #ddd",
    marginBottom: "20px",
    paddingBottom: "10px",
  },
});

const NewsListViewDesktop = ({
  locale,
  location,
  header,
  footer,
  siteSetting,
  data,
  pageTitleVariants,
  categories,
  margin,
  padding,

  numberPerPage,
  presentation,
  sectionType,
  titleVariants,
  anchorLink,
  reloadRequired,
}) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight="100vh"
    >
      <SEO siteSetting={siteSetting} />
      <Header
        header={header}
        siteSetting={siteSetting}
        location={location}
        reloadRequired={reloadRequired}
      />
      <Container style={{ flex: 1 }}>
        <Box py={8}>
          <Typography variant="h4" className={classes.row}>
            {getLocaleValue(locale, pageTitleVariants).content}
          </Typography>

          <Box width="100%" display="flex">
            <Box flexShrink="0">
              <NewsCategoryListController
                categories={categories}
                location={location}
                currentNewsCategory={null}
              />
            </Box>
            <Box>
              {data?.map((n, i) => (
                <NewsListItemController key={i} data={n} />
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
      <Footer footer={footer} siteSetting={siteSetting} />
    </Box>
  );
};

export default NewsListViewDesktop;
