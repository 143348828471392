import React from "react";
import { useSelector } from "react-redux";

import Parser from "html-react-parser";
import { getLocaleValue } from "../../../../helpers/get-locale-value";
import { getLocaleValueFromArrayByName } from "../../../../helpers/get-locale-value-from-array-by-name";

// import Img from "gatsby-image";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardMedia, Box, Grid, Typography } from "@material-ui/core";

import Moment from "react-moment";
import "moment/locale/zh-cn";
import "moment/locale/th";
import "moment/locale/id";
import "moment/locale/es";
import "moment/locale/pt";
import { extractImagePathUrl } from "../../../shared-components/image/image-url-helper";

const useStyles = makeStyles({
  row: {
    borderColor: "#ddd",
  },
});

const NewsListItemViewDesktop = ({ locale, data }) => {
  const { variants, news_category, announced_at } = data;

  const classes = useStyles();
  const newsPost = getLocaleValue(locale, variants);
  const updated_date = new Date(announced_at);

  const copywriting_variants = useSelector(state => {
    return state.siteSettingReducer.copywriting_variants;
  }, []);

  return (
    <Box
      display="flex"
      direction="row"
      borderBottom={1}
      m={2}
      p={2}
      pb={4}
      className={classes.row}
    >
      <Grid container spacing={3}>
        <Grid container item xs={3} sm={4}>
          {newsPost.banner && (
            <Card className={`media-wrapper`}>
              <CardMedia
                className={`media`}
                image={extractImagePathUrl(newsPost.banner)}
              />
            </Card>
          )}
        </Grid>
        <Grid container item xs={9} sm={8}>
          <Grid>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              <Box>
                <Box width="100%" fontWeight="bold" mb={2}>
                  <Typography variant="h6">{newsPost.title}</Typography>
                </Box>
                <Box width="100%">{Parser(newsPost.html_summary)}</Box>
              </Box>
              <Box>
                {copywriting_variants &&
                  getLocaleValueFromArrayByName(
                    locale,
                    copywriting_variants,
                    "copywriting-postedon"
                  ).content}{" "}
                <Moment
                  locale={locale}
                  format={
                    locale.toLowerCase() == "zh-cn" ? "LL" : "DD MMM YYYY"
                  }
                  date={updated_date}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default NewsListItemViewDesktop;
