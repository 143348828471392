import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { graphql } from "gatsby";

import NewsListViewDesktop from "./news-list-view-desktop";
import NewsListViewTablet from "./news-list-view-tablet";
import NewsListViewPhone from "./news-list-view-phone";

import ResponsiveWrapper from "../../../shared-components/responsive-wrapper";
import { getLocaleValue } from "../../../../helpers/get-locale-value";
import { getMetaDetails } from "../../../../helpers/get-meta-details";

// , filter: {pinned_card: {pinned: {eq: true}}}
// export const query = graphql`
//   query news_list($dataLimit: Int, $slug: String) {
//     allStrapiNewsPost(
//       sort: { order: DESC, fields: announced_at }
//       filter: { news_category: { slug: { eq: $slug } } }
//       limit: $dataLimit
//     ) {
//       nodes {
//         id: strapiId
//         announced_at
//         name
//         slug
//         variants {
//           banner {
//             childImageSharp {
//               fluid(maxWidth: 400, srcSetBreakpoints: [600], quality: 80) {
//                 ...GatsbyImageSharpFluid_noBase64
//               }
//             }
//           }
//           forum_thread_id
//           forum_url
//           html_full_content
//           html_summary
//           title
//           name
//           id
//           locale {
//             code
//           }
//         }
//         news_category {
//           id
//           name
//           slug
//           variants {
//             content
//             locale {
//               code
//             }
//           }
//         }
//       }
//     }
//   }
// `;

const NewsListController = ({ data, pageContext, location }) => {
  const {
    // slug,
    // dataLimit,
    // dataFilter,

    newspage,
    newsPosts,
    categories,

    header,
    footer,
    siteSetting,
    reloadRequired,
    meta_details_variants,
    // pageTitleVariants,
    // margin,
    // padding,
    // zIndex,
    // isDisplayTitle,
    // numberPerPage,
    // presentation,
    // sectionType,
    // titleVariants,
    // anchorLink,
  } = pageContext;

  const {
    page_title_variants: pageTitleVariants,
    margin,
    padding,
    zIndex,
    isDisplayTitle,
    numberPerPage,
    presentation,
    sectionType,
    titleVariants,
    anchorLink,
    category_tabs,
  } = newspage;
  // const { nodes: posts } = data.allStrapiNewsPost;

  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  const [latestNews, setLatestNews] = useState([]);

  const seoData = getMetaDetails(
    locale,
    meta_details_variants,
    siteSetting,
    siteSetting
  );

  useEffect(() => {
    const news = _.filter(newsPosts, newsPost => {
      const { announced_at } = newsPost;

      if (moment().utc().isAfter(moment.utc(announced_at))) {
        return true;
      } else {
        return false;
      }
    });

    const sortedNews = _.orderBy(
      news,
      [
        newsPost => {
          return newsPost.announced_at;
        },
        newsPost => {
          return newsPost.created_at;
        },
      ],
      ["desc", "desc"]
    );

    setLatestNews(sortedNews);
  }, [data]);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <NewsListViewDesktop
          locale={locale}
          location={location}
          data={latestNews}
          categories={categories}
          header={header}
          footer={footer}
          siteSetting={seoData}
          pageTitleVariants={pageTitleVariants}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
          isDisplayTitle={isDisplayTitle}
          numberPerPage={numberPerPage}
          presentation={presentation}
          sectionType={sectionType}
          titleVariants={titleVariants}
          anchorLink={anchorLink}
          reloadRequired={reloadRequired}
        />
      }
      TabletComponent={
        <NewsListViewTablet
          locale={locale}
          data={latestNews}
          location={location}
          header={header}
          footer={footer}
          siteSetting={seoData}
          pageTitleVariants={pageTitleVariants}
          categories={categories}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
          isDisplayTitle={isDisplayTitle}
          numberPerPage={numberPerPage}
          presentation={presentation}
          sectionType={sectionType}
          titleVariants={titleVariants}
          anchorLink={anchorLink}
          reloadRequired={reloadRequired}
        />
      }
      PhoneComponent={
        <NewsListViewPhone
          locale={locale}
          data={latestNews}
          location={location}
          header={header}
          footer={footer}
          siteSetting={seoData}
          pageTitleVariants={pageTitleVariants}
          categories={categories}
          margin={margin}
          padding={padding}
          zIndex={zIndex}
          isDisplayTitle={isDisplayTitle}
          numberPerPage={numberPerPage}
          presentation={presentation}
          sectionType={sectionType}
          titleVariants={titleVariants}
          anchorLink={anchorLink}
          reloadRequired={reloadRequired}
        />
      }
    />
  );
};
export default NewsListController;
