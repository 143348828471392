import React from "react";
import { useSelector } from "react-redux";

import NewsListItemViewDesktop from "./news-list-item-view-desktop";
import NewsListItemViewTablet from "./news-list-item-view-tablet";
import NewsListItemViewPhone from "./news-list-item-view-phone";

import ResponsiveWrapper from "../../../shared-components/responsive-wrapper";
import Navigation from "../../../shared-components/navigation";

const NewsListItemController = ({ data }) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <Navigation to={`/news/${data.slug ? data.slug : data.id}`}>
          <NewsListItemViewDesktop locale={locale} data={data} />
        </Navigation>
      }
      TabletComponent={
        <Navigation to={`/news/${data.slug ? data.slug : data.id}`}>
          <NewsListItemViewTablet locale={locale} data={data} />
        </Navigation>
      }
      PhoneComponent={
        <Navigation to={`/news/${data.slug ? data.slug : data.id}`}>
          <NewsListItemViewPhone locale={locale} data={data} />
        </Navigation>
      }
    />
  );
};

export default NewsListItemController;
